import { createContext, useContext, useEffect, useState } from "react";
import { protectedRoute } from "../api/authUser"; // Importa las funciones de solicitud a la API relacionadas con la autenticación

export const PersonProfileContext = createContext(); // Crea el contexto para la autenticación

// Hook para usar el contexto de autenticación
export const useProfileAuth = () => {
  const context = useContext(PersonProfileContext);
  if (!context) {
    throw new Error("useProfileAuth must be used within a ProfileAuthProvider"); // Lanza un error si useProfileAuth se usa fuera del proveedor
  }
  return context;
};

export const ProfileAuthProvider = ({ children }) => {
  const [userProfile, setUserProfile] = useState(null);

  // Obtener un perfil específico por username
  const getUserProfileByUsername = async (username) => {
    try {
      const res = await protectedRoute().get(`/${username}`);
      setUserProfile(res.data)
      console.log(res.data,33333);
      
      return res.data; // Devuelve los datos del usuario obtenidos del backend
    } catch (error) {
      console.error("Error fetching user:", error);
      throw error; // Lanza el error para que pueda ser manejado por el componente llamador
    }
  };

  return (
    <PersonProfileContext.Provider
      value={{ userProfile, setUserProfile, getUserProfileByUsername }}
    >
      {children}
    </PersonProfileContext.Provider>
  );
};
