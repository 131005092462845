import { createContext, useContext, useState } from "react";
import { protectedRoute } from "../api/authUser"; // Importa la función de ruta protegida para las solicitudes autenticadas

const ProductsContext = createContext(); // Crea el contexto para las soluciones

export const useProducts = () => {
  const context = useContext(ProductsContext); // Utiliza el contexto de soluciones

  if (!context) {
    throw new Error("useProducts must be used within a ProductsProvider"); // Lanza un error si useSolutions se usa fuera del proveedor
  }
  return context;
};

export const ProductsProvider = ({ children }) => {
  const [products, setProducts] = useState([]); // Estado para almacenar los productos
  const [productsRenting, setProductsRenting] = useState([]); // Estado para almacenar los productos

  // Función para obtener un subconjunto de soluciones
  const getProducts = async () => {
    try {
      const res = await protectedRoute().get("/products-stock");
      console.log(res.data);

      setProducts(res.data); // Establece las soluciones obtenidas en el estado
    } catch (error) {
      console.log(error); // Maneja los errores de la solicitud
    }
  };

  // Función para obtener una solución específica por ID
  const getProductById = async (id) => {
    try {
      const res = await protectedRoute().get(`/products-stock/${id}`);
      return res.data; // Devuelve los datos de la solución específica
    } catch (error) {
      console.log(error); // Maneja los errores de la solicitud
    }
  };

  const createProducts = async (solutionData) => {
    try {
      const res = await protectedRoute().post("/products-stock", solutionData);
      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

  const updateProducts = async (id, solutionData) => {
    try {
      const res = await protectedRoute().put(
        `/products-stock/${id}`,
        solutionData
      );
      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

  // Función para eliminar una solución
  const deleteProducts = async (id) => {
    try {
      const res = await protectedRoute().delete(`/products-stock/${id}`);
      return res.data; // Devuelve la respuesta de la eliminación
    } catch (error) {
      console.log(error); // Maneja los errores de la solicitud
    }
  };

  // Función para obtener un subconjunto de soluciones
  const getProductsRenting = async () => {
    try {
      const res = await protectedRoute().get("/products-renting-stock");
      console.log(res.data);

      setProductsRenting(res.data); // Establece las soluciones obtenidas en el estado
    } catch (error) {
      console.log(error); // Maneja los errores de la solicitud
    }
  };

  // Función para obtener una solución específica por ID
  const getProductRentingById = async (id) => {
    try {
      const res = await protectedRoute().get(`/products-renting-stock/${id}`);
      return res.data; // Devuelve los datos de la solución específica
    } catch (error) {
      console.log(error); // Maneja los errores de la solicitud
    }
  };

  const createProductsRenting = async (solutionData) => {
    try {
      const res = await protectedRoute().post(
        "/products-renting-stock",
        solutionData
      );
      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

  const updateProductsRenting = async (id, solutionData) => {
    try {
      const res = await protectedRoute().put(
        `/products-renting-stock/${id}`,
        solutionData
      );
      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

  // Función para eliminar una solución
  const deleteProductsRenting = async (id) => {
    try {
      const res = await protectedRoute().delete(
        `/products-renting-stock/${id}`
      );
      return res.data; // Devuelve la respuesta de la eliminación
    } catch (error) {
      console.log(error); // Maneja los errores de la solicitud
    }
  };

  return (
    <ProductsContext.Provider
      value={{
        products,
        getProducts,
        getProductById,
        createProducts,
        updateProducts,
        deleteProducts,
        productsRenting,
        getProductsRenting,
        getProductRentingById,
        createProductsRenting,
        updateProductsRenting,
        deleteProductsRenting,
      }}
    >
      {children}{" "}
      {/* Renderiza los componentes hijos que estarán dentro del proveedor */}
    </ProductsContext.Provider>
  );
};
