import React, { useContext, useState } from "react";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import { CartContext } from "../../Context/CartContext";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { ThemeContext } from "../../Context/ThemeContext";

function CartShopping() {
  const { darkMode } = useContext(ThemeContext);

  const { cartItems, removeFromCart, updateQuantity, clearCart } =
    useContext(CartContext);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const [isLoading, setIsLoading] = useState(false); // Para controlar el estado de carga

  // Manejar cambios en los campos del formulario
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Manejar el envío del formulario
  const handleSubmit = async (e) => {
    e.preventDefault();

    const dataToSend = {
      ...formData,
      cartItems,
    };

    // Mostrar el loading usando SweetAlert2
    Swal.fire({
      title: "Enviando cotización...",
      text: "Por favor, espera mientras se envía el correo.",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading(); // Mostrar el spinner de carga
      },
    });

    setIsLoading(true);

    try {
      const response = await fetch("http://localhost:10101/products-shopping", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dataToSend),
      });

      const result = await response.json();
      setIsLoading(false);

      if (result.Status === "Correo enviado con éxito") {
        Swal.fire({
          icon: "success",
          title: "¡Correo enviado!",
          text: "La cotización ha sido enviada correctamente.",
        });

        // Limpiar el carrito después del envío exitoso
        clearCart();
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Hubo un error al enviar el correo. Por favor, intenta de nuevo.",
        });
      }
    } catch (error) {
      setIsLoading(false);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Hubo un error en el servidor. Por favor, intenta más tarde.",
      });
    }
  };

  // Manejar el cambio de cantidad en un producto
  const handleQuantityChange = (id, newQuantity) => {
    if (newQuantity < 1) return;
    updateQuantity(id, parseInt(newQuantity, 10));
  };

  return (
    <>
      <Navbar />
      <div className={`w-full flex flex-col items-center justify-center min-h-[100vh] ${darkMode ? "bg-gray-900 text-white" : ""}`}>
        <h2 className="text-2xl font-bold mb-8 mt-32">Productos a cotizar</h2>

        {cartItems.length === 0 ? (
          <div>
            <p>No hay productos en la cotización.</p>
            <Link to="/stock-devices">
              <button className="w-full bg-blue-600 text-white px-4 py-2 mt-2 rounded-b-md">
                Volver
              </button>
            </Link>
          </div>
        ) : (
          <div className="w-full flex flex-col items-center">
            <table className={`w-[80%] ${darkMode ? "bg-gray-400 text-black" : ""}`}>
              <thead>
                <tr>
                  <th className="py-2 text-black">Producto</th>
                  <th className="py-2 text-black">Nombre</th>
                  <th className="py-2 text-black">Descripción</th>
                  <th className="py-2 text-black">Cantidad</th>
                  <th className="py-2 text-black">Acciones</th>
                </tr>
              </thead>
              <tbody>
                {cartItems.map((item, index) => (
                  <tr key={index} className="text-center">
                    <td className="py-2">
                      {item.images && item.images.length > 0 ? (
                        <img
                          src={item.images[0]}
                          alt={item.name}
                          className="h-28 object-contain"
                        />
                      ) : (
                        <span>Imagen no disponible</span>
                      )}
                    </td>
                    <td className="py-2">{item.name}</td>
                    <td className="py-2">{item.description}</td>
                    <td className="py-2">
                      <input
                        type="number"
                        value={item.quantity}
                        onChange={(e) =>
                          handleQuantityChange(item.id, e.target.value)
                        }
                        className="w-20 border border-gray-300 rounded px-2"
                        min="1"
                      />
                    </td>
                    <td className="py-2">
                      <button
                        onClick={() => removeFromCart(item.id)}
                        className="bg-red-600 text-white px-4 py-2 rounded-lg"
                      >
                        Eliminar
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            <div className="w-[80%]">
              <Link to="/stock-devices">
                <button className="w-full bg-blue-600 text-white px-4 py-2 mt-2 rounded-b-md">
                  Volver
                </button>
              </Link>
            </div>
          </div>
        )}

        <div className={`w-[80%] border p-4 rounded-lg my-4 ${darkMode ? "bg-gray-600 text-white" : ""}`}>
          <h3 className="text-xl font-bold mb-2">
            Llena la información para realizar la cotización de los productos:
          </h3>
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              name="name"
              placeholder="Nombre"
              value={formData.name}
              onChange={handleInputChange}
              className="border border-gray-300 px-4 py-2 mb-2 w-full"
              required
            />
            <input
              type="email"
              name="email"
              placeholder="Correo"
              value={formData.email}
              onChange={handleInputChange}
              className="border border-gray-300 px-4 py-2 mb-2 w-full"
              required
            />
            <input
              type="text"
              name="phone"
              placeholder="Teléfono"
              value={formData.phone}
              onChange={handleInputChange}
              className="border border-gray-300 px-4 py-2 mb-2 w-full"
              required
            />
            <textarea
              name="message"
              placeholder="Cuentanos que duda tienes, o qué necesitas para la cotización del/los productos."
              value={formData.message}
              onChange={handleInputChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              rows="5"
            />
            <button
              type="submit"
              className={`bg-blue-600 text-white px-4 py-2 rounded-lg mt-4 ${darkMode ? "bg-gray-900" : ""}`}
              disabled={isLoading} // Deshabilitar botón si está en loading
            >
              {isLoading ? "Enviando..." : "Enviar"}
            </button>
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default CartShopping;
