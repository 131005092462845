import React, { useContext, useState } from "react";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import { useParams, Link } from "react-router-dom";
import { CartContext } from "../../Context/CartContext";
import { useProducts } from "../../Context/ProductsContext"; // Importa el contexto de productos
import { Carousel } from "@material-tailwind/react";
import "./ecommerce.css";
import { ThemeContext } from "../../Context/ThemeContext";

function InfoProductRentingById() {
  const { addToCart, cartItems } = useContext(CartContext); // Usar el contexto del carrito
  const { productsRenting } = useProducts(); // Usar el contexto de productos
  const { id } = useParams(); // Obtiene el ID del producto desde la URL
  const product = productsRenting.find((p) => p.id === parseInt(id)); // Encuentra el producto por ID
  const { darkMode } = useContext(ThemeContext);

  // Filtra productos que no sean el seleccionado
  const otherProducts = productsRenting.filter((p) => p.id !== parseInt(id));
  const [quantities, setQuantities] = useState({}); // Estado para las cantidades

  // Función para manejar el cambio de cantidad de un producto
  const handleQuantityChange = (id, value) => {
    setQuantities((prevQuantities) => ({
      ...prevQuantities,
      [id]: value,
    }));
  };

  // Obtener la cantidad actual en el carrito para un producto dado
  const getCartItemQuantity = (id) => {
    const cartItem = cartItems.find((item) => item.id === id);
    return cartItem ? cartItem.quantity : 0;
  };

  return (
    <>
      <Navbar />
      <div
        className={`w-full flex flex-col items-center justify-center mt-22 ${
          darkMode ? "bg-gray-900 text-white" : ""
        }`}
      >
        {product ? (
          <div
            className={`container-full-e w-[80%] p-8 rounded-lg ${
              darkMode ? "bg-gray-900" : ""
            }`}
          >
            <div className="w-full flex flex-col justify-center items-center">
              <h1 className="text-3xl font-bold mb-4 mt-32">{product.name}</h1>
              <Carousel
                autoplay={true}
                loop={true}
                nextArrow={false}
                prevArrow={false}
                className="w-full h-full"
              >
                {product.images && product.images.length > 0 ? (
                  product.images.map((imgSrc, imgIndex) => (
                    <div key={imgIndex} className="w-full flex justify-center">
                      <img
                        src={imgSrc}
                        alt={`${product.name} image ${imgIndex + 1}`}
                        className="h-64 object-contain mb-10"
                      />
                    </div>
                  ))
                ) : (
                  <div>Imagen no disponible</div>
                )}
              </Carousel>
            </div>
            <p className="text-lg mb-4">{product.description}</p>

            {/* Información adicional del producto */}
            <div className="grid grid-cols-1 gap-4">
              <div
                className={`border p-4 rounded-lg ${
                  darkMode ? "bg-gray-400 text-black" : ""
                }`}
              >
                <h3 className="text-xl font-bold mb-2">
                  Lo que tienes que saber:
                </h3>
                <p className="whitespace-pre-line">{product.more_information}</p>
              </div>
              <div className="w-full flex justify-start gap-8">
                <button
                  onClick={() => addToCart(product)} // Agregar a cotizar
                  className={`txt-card2 bg-blue-300 text-white px-4 py-2 rounded-lg text-sm ${
                    darkMode ? "bg-gray-800" : ""
                  }`}
                >
                  Agregar a cotizar
                </button>
                <Link to="/stock-cart">
                  <button
                    className={`bg-blue-600 text-white px-4 py-2 rounded-lg relative ${
                      darkMode ? "bg-gray-500" : ""
                    }`}
                  >
                    Carrito
                    {cartItems.length >= 0 && (
                      <span className="absolute top-0 right-0 left-16 inline-block w-6 h-6 text-center text-white bg-red-600 rounded-full">
                        {cartItems.length}
                      </span>
                    )}
                  </button>
                </Link>
              </div>
            </div>
            {/* Otros productos que te pueden interesar */}
            <div className="w-full mt-12">
              <h2 className="text-2xl font-bold mb-4">
                Otros productos que te pueden interesar
              </h2>
              <div className="cards-cols grid grid-cols-4 gap-4">
                {otherProducts.map((item) => {
                  const currentQuantity = getCartItemQuantity(item.id); // Obtener la cantidad actual del carrito para este producto

                  return (
                    <div
                      key={item.id}
                      className={`border p-4 rounded-lg bg-[#0E4980] flex flex-col items-center ${
                        darkMode ? "bg-gray-700" : ""
                      }`}
                    >
                      <div
                        className={`bg-[#C2DBF2] w-full flex flex-col justify-center items-center rounded-md ${
                          darkMode ? "bg-gray-500" : ""
                        }`}
                      >
                        <Carousel
                          autoplay={true}
                          loop={true}
                          nextArrow={false}
                          prevArrow={false}
                          className="w-full h-full"
                        >
                          {item.images && item.images.length > 0 ? (
                            item.images.map((imgSrc, imgIndex) => (
                              <div
                                key={imgIndex}
                                className="w-full flex justify-center"
                              >
                                <img
                                  src={imgSrc}
                                  alt={`${item.name} image ${imgIndex + 1}`}
                                  className="h-60 object-contain mb-10"
                                />
                              </div>
                            ))
                          ) : (
                            <div>Imagen no disponible</div>
                          )}
                        </Carousel>
                      </div>
                      <br />
                      <div className="w-full h-20 flex justify-start">
                        <h3 className="text-lg text-white font-semibold mb-4">
                          {item.name}
                        </h3>
                      </div>
                      <div className="w-full flex justify-center gap-4">
                        <button
                          className={`txt-card bg-blue-700 text-white px-4 py-2 rounded-lg w-[30%] text-sm ${
                            darkMode ? "bg-gray-500" : ""
                          }`}
                        >
                          <Link to={`/stock-devices-renting/${item.id}`}>Ver más</Link>
                        </button>
                        <input
                          type="number"
                          min="1"
                          value={quantities[item.id] || currentQuantity || 1} // Mostrar cantidad del carrito o 1
                          onChange={(e) =>
                            handleQuantityChange(
                              item.id,
                              parseInt(e.target.value)
                            )
                          }
                          className={`border rounded-md p-2 w-[15%] ${
                            darkMode ? "text-black" : ""
                          }`}
                        />
                        <button
                          onClick={
                            () =>
                              addToCart(item, quantities[item.id] || 1, true) // 'true' para reemplazar la cantidad
                          }
                          className={`txt-card2 bg-blue-300 text-white px-4 py-2 rounded-lg w-[60%] text-sm ${
                            darkMode ? "bg-gray-800" : ""
                          }`}
                        >
                          Agregar a cotizar
                        </button>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>

            {/* Botón para volver a la página anterior */}
            <div className="w-full">
              <Link to="/stock-devices">
                <button className="w-full bg-blue-600 text-white px-4 py-2 mt-2 rounded-b-md">
                  Volver
                </button>
              </Link>
            </div>
          </div>
        ) : (
          <p>Producto no encontrado</p>
        )}
      </div>

      <Footer />
    </>
  );
}

export default InfoProductRentingById;
