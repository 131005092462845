import React, { useState, useEffect, useContext } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import NavbarAdmin from "../../components/Navbar/NavbarAdmin";
import { ThemeContext } from "../../Context/ThemeContext";
import FooterAmin from "../../components/Footer/FooterAmin";
import { useProducts } from "../../Context/ProductsContext";

function FormCreateProducts() {
  const { register, handleSubmit, setValue } = useForm();
  const { createProducts, getProductById, updateProducts } = useProducts();
  const navigate = useNavigate();
  const params = useParams();
  const { darkMode } = useContext(ThemeContext);

  const [images, setImages] = useState([]); // Para las nuevas imágenes
  const [previewImages, setPreviewImages] = useState([]); // Para mostrar las imágenes previas
  const [isUpdating, setIsUpdating] = useState(false);

  useEffect(() => {
    const loadProduct = async () => {
      if (params.id) {
        const product = await getProductById(params.id);
        setValue("name", product.name);
        setValue("description", product.description);
        setValue("price", product.price);
        setValue("stock", product.stock);
        setValue("more_information", product.more_information);

        // Establecer las imágenes previas si existen
        if (product.images && product.images.length > 0) {
          setPreviewImages(product.images); // Aquí asumimos que es un array de URLs
        }
        setIsUpdating(true);
      }
    };
    loadProduct();
  }, [params.id, setValue, getProductById]);

  const onSubmit = handleSubmit(async (data) => {
    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("description", data.description);
    formData.append("price", data.price);
    formData.append("stock", data.stock);
    formData.append("more_information", data.more_information);
    formData.append("category", data.category);

    images.forEach((image, index) => {
      formData.append(`images`, image);
    });

    console.log([...formData]); // <-- Verifica que todos los datos se estén añadiendo correctamente

    if (params.id) {
      await updateProducts(params.id, formData);
    } else {
      await createProducts(formData);
    }

    navigate("/ecommerce-panel");
  });

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    setImages(files); // Guardar las nuevas imágenes

    // Crear una vista previa de las nuevas imágenes
    const previews = files.map((file) => URL.createObjectURL(file));

    // Reemplazar las imágenes nuevas sin mezclar con las anteriores
    setPreviewImages((prevPreviews) => [...previews]);
  };

  return (
    <>
      <NavbarAdmin />
      <div
        className={`w-full flex justify-center ${
          darkMode ? "bg-[#424242] text-white" : "bg-gray-100"
        }`}
      >
        <div className="h-full pt-[8%] pb-[4%]">
          <form
            onSubmit={onSubmit}
            className={`p-6 rounded shadow-md ${
              darkMode ? "bg-[#212121]" : "bg-white"
            }`}
          >
            <h2 className="text-2xl font-bold mb-4">
              {isUpdating ? "Actualizar producto" : "Crear nuevo producto"}
            </h2>

            <div className="mb-4">
              <label htmlFor="name" className="block text-sm font-bold mb-2">
                Nombre:
              </label>
              <input
                type="text"
                id="name"
                className="border rounded w-full py-2 px-3"
                placeholder="Nombre del producto"
                {...register("name")}
              />
            </div>

            <div className="mb-4">
              <label
                htmlFor="description"
                className="block text-sm font-bold mb-2"
              >
                Descripción:
              </label>
              <textarea
                id="description"
                className="border rounded w-full py-2 px-3"
                rows="5"
                placeholder="Descripción del producto"
                {...register("description")}
              ></textarea>
            </div>

            <div className="mb-4">
              <label
                htmlFor="description"
                className="block text-sm font-bold mb-2"
              >
                Detalles del producto:
              </label>
              <textarea
                id="description"
                className="border rounded w-full py-2 px-3"
                rows="5"
                placeholder="Descripción del producto"
                {...register("more_information")}
              ></textarea>
            </div>

            <div className="mb-4">
              <label htmlFor="price" className="block text-sm font-bold mb-2">
                Precio:
              </label>
              <input
                type="number"
                id="price"
                className="border rounded w-full py-2 px-3"
                placeholder="Precio del producto"
                {...register("price")}
              />
            </div>

            <div className="mb-4">
              <label htmlFor="stock" className="block text-sm font-bold mb-2">
                Stock:
              </label>
              <input
                type="number"
                id="stock"
                className="border rounded w-full py-2 px-3"
                placeholder="Cantidad en stock"
                {...register("stock")}
              />
            </div>

            <div className="mb-4">
              <label
                htmlFor="category"
                className="block text-sm font-bold mb-2"
              >
                Categoría:
              </label>
              <select
                id="category"
                className="border rounded w-full py-2 px-3"
                {...register("category")}
              >
                <option value="DaaS">DaaS</option>
                <option value="IaaS">IaaS</option>
                <option value="Perifericos">Periféricos</option>
              </select>
            </div>

            <div className="mb-4">
              <label htmlFor="images" className="block text-sm font-bold mb-2">
                Imágenes:
              </label>
              {previewImages.length > 0 && (
                <div className="flex space-x-4">
                  {previewImages.map((src, index) => (
                    <img
                      key={index}
                      src={src}
                      alt={`preview-${index}`}
                      className="w-20 h-20 object-cover"
                    />
                  ))}
                </div>
              )}
              <input
                type="file"
                id="images"
                className="mt-2"
                multiple
                onChange={handleImageChange}
              />
            </div>

            <button
              type="submit"
              className="bg-blue-500 text-white py-2 px-4 rounded"
            >
              {isUpdating ? "Actualizar" : "Crear"}
            </button>
            <button className="bg-[#800e10] text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
              <Link to="/ecommerce-panel">Cancelar</Link>
            </button>
          </form>
        </div>
      </div>
      <FooterAmin />
    </>
  );
}

export default FormCreateProducts;
