import React, { useEffect } from "react";
import pdfPort from "../../../public/port.pdf";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import { useBlog } from "../../Context/BlogContext";
import { useNavigate, useParams } from "react-router-dom"; // Importa useParams para obtener el username
import { useProducts } from "../../Context/ProductsContext";
import { useProfileAuth } from "../../Context/PersonProfileContext";

const PersonProfile = () => {
  const { articles, getArticles } = useBlog();
  const { products, getProducts } = useProducts();
  const { username } = useParams(); // Obtén el username de la URL
  const navigate = useNavigate(); // Para la navegación programática

  const { userProfile, getUserProfileByUsername } = useProfileAuth();

  useEffect(() => {
    getArticles();
    getProducts();
    if (username) {
      getUserProfileByUsername(username); // Pasa el username a la función
    }
  }, [username]); // Asegúrate de ejecutar el efecto cuando cambie el username

  const handleReadMore = (articleId) => {
    navigate(`/blog/${articleId}`); // Navega a la página del blog con el id del artículo
  };

  const handleProductMore = (productId) => {
    navigate(`/stock-devices/${productId}`); // Navega a la página del producto con el id del producto
  };

  return (
    <>
      <Navbar />
      <div className="min-h-screen p-10 mt-16">
        <div className="w-[80%] mx-auto bg-[#DFE1E3] shadow-lg rounded-lg overflow-hidden">
          <div className="w-full flex">
            {/* Columna izquierda - Información personal */}
            {userProfile ? ( // Asegúrate de que userProfile exista
              <div className="bg-[#0E4980] p-6 flex flex-col w-[45%]">
                <div className="flex items-center justify-start gap-4 pb-8">
                  <h1 className="text-4xl font-bold text-blue-gray-100 mb-2">
                    {userProfile.User.name}{" "}
                    {/* Muestra el nombre real del usuario */}
                  </h1>
                  <img
                    src={
                      userProfile.User.image_profile ||
                      "https://www.pngall.com/wp-content/uploads/5/Profile-Male-PNG.png"
                    }
                    alt="Profile"
                    className="w-32 h-32 rounded-full shadow-md"
                  />
                </div>
                <p className="text-gray-100 mb-6">
                  {userProfile.User.about_me ||
                    "No hay descripción disponible."}
                </p>
                <p className="text-gray-100 font-semibold mb-6">
                  {userProfile.User.area || "Area."}
                </p>
                <p className="text-gray-100 mb-6">
                  {userProfile.position || "Posición no especificada."}
                </p>

                {/* Contacto */}
                <div className="bg-[#BEDDF9] p-4 rounded-lg">
                  <h3 className="text-3xl font-bold mb-2">Contáctame</h3>
                  <p className="mb-2">
                    <a
                      href={`mailto:${userProfile.User.email}`}
                      className="text-blue-900"
                    >
                      {userProfile.User.email}
                    </a>
                  </p>
                  <p className="mb-4">
                    {userProfile.User.telephone || "Teléfono no especificado"}
                  </p>
                </div>
              </div>
            ) : (
              <p>Cargando perfil...</p>
            )}

            {/* Columna derecha - Proyectos */}
            <div className="w-[55%] p-6">
              <h2 className="text-3xl font-bold text-light-blue-900 mb-4">
                Portafolio
              </h2>

              {/* Mostrar el PDF usando un iframe */}
              <div className="w-full h-[600px]">
                <iframe
                  src={
                    userProfile &&
                    userProfile.User &&
                    userProfile.User.portfolio_url
                      ? userProfile.User.portfolio_url.replace(
                          "/raw/",
                          "/upload/"
                        ) +".pdf"
                      : pdfPort
                  }
                  title="Portafolio PDF"
                  className="w-full h-full border border-gray-300 rounded-lg shadow-md"
                />
              </div>
            </div>
          </div>
        </div>

        {/* Sección de Productos Tecnológicos */}
        <div className="w-[80%] mx-auto mt-10">
          <h2 className="text-3xl font-bold text-gray-800 mb-6">
            Productos que te pueden interesar
          </h2>
          <div className="grid grid-cols-2 gap-6">
            {products
              .filter((product) => product.category === "DaaS") // Filtra los productos por categoría "DaaS"
              .slice(0, 4) // Limita a los primeros 4 productos
              .map((product, index) => (
                <div key={index} className="bg-white shadow-lg rounded-lg p-4">
                  <div className="flex justify-center">
                    <img
                      src={product.images}
                      alt={product.name}
                      className="h-40 object-cover rounded-lg mb-4"
                    />
                  </div>
                  <h3 className="text-2xl font-bold mb-2">{product.name}</h3>
                  <p className="text-gray-700 mb-4">{product.description}</p>
                  <p className="text-green-600 font-bold mb-4">
                    {product.price}
                  </p>
                  <button
                    onClick={() => handleProductMore(product.id)} // Al hacer clic, navega al producto específico
                    className="text-blue-500 hover:underline"
                  >
                    Ver producto
                  </button>
                </div>
              ))}
          </div>
        </div>

        {/* Sección de Artículos del Blog */}
        <div className="w-[80%] mx-auto mt-10">
          <h2 className="text-3xl font-bold text-gray-800 mb-6">
            No olvides estar actualizado con las últimas noticias
          </h2>
          <div className="grid grid-cols-2 gap-6">
            {articles.slice(0, 4).map((article, index) => (
              <div key={index} className="bg-white shadow-lg rounded-lg p-4">
                <img
                  src={article.image}
                  alt={article.title}
                  className="w-full h-40 object-cover rounded-lg mb-4"
                />
                <h3 className="text-2xl font-bold mb-2">{article.title}</h3>
                <p className="text-gray-700 mb-4">{article.description}</p>
                <button
                  onClick={() => handleReadMore(article.id)} // Al hacer clic, navega al artículo específico
                  className="text-blue-500 hover:underline"
                >
                  Leer más
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PersonProfile;
