import React, { useContext, useEffect, useState } from "react";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import { useProducts } from "../../Context/ProductsContext";
import { Carousel } from "@material-tailwind/react";
import { CartContext } from "../../Context/CartContext";
import { Link } from "react-router-dom";
import "./ecommerce.css";
import { ThemeContext } from "../../Context/ThemeContext";

function Ecommerce() {
  const { products, getProducts, productsRenting, getProductsRenting } =
    useProducts();
  const { addToCart, cartItems } = useContext(CartContext);
  const { darkMode } = useContext(ThemeContext);
  const [randomRentingProduct, setRandomRentingProduct] = useState(null); // Estado para el producto aleatorio

  useEffect(() => {
    if (productsRenting.length > 0) {
      // Elegir un producto aleatorio de renting
      const randomIndex = Math.floor(Math.random() * productsRenting.length);
      setRandomRentingProduct(productsRenting[randomIndex]);
    }
  }, [productsRenting]);

  // Cargar productos al montar el componente
  useEffect(() => {
    getProducts();
    getProductsRenting();
  }, []);

  const [searchTerm, setSearchTerm] = useState(""); // Estado para el término de búsqueda
  const [quantities, setQuantities] = useState({}); // Estado para las cantidades
  const [selectedBrand, setSelectedBrand] = useState("todos"); // Estado para la marca seleccionada

  // Filtrar productos según el término de búsqueda
  const filteredProducts = products.filter((product) =>
    product.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const filteredProductsRenting = productsRenting
    .filter((product) =>
      product.name.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .filter(
      (product) =>
        selectedBrand === "todos" ||
        product.mark.toLowerCase() === selectedBrand
    );

  // Filtrar productos por categoría
  const desktopRentingProducts = filteredProductsRenting.filter(
    (product) => product.category === "Escritorio"
  );
  const laptopRentingProducts = filteredProductsRenting.filter(
    (product) => product.category === "Portatil"
  );

  // Función para manejar el cambio de cantidad de un producto
  const handleQuantityChange = (id, value) => {
    setQuantities((prevQuantities) => ({
      ...prevQuantities,
      [id]: value,
    }));
  };

  // Obtener la cantidad actual en el carrito para un producto dado
  const getCartItemQuantity = (id) => {
    const cartItem = cartItems.find((item) => item.id === id);
    return cartItem ? cartItem.quantity : 0;
  };

  // Renderizar productos por categoría
  const renderProducts = (categoryProducts, categoryName) => (
    <div className="container-full-e w-[80%] my-8">
      <h2 className="text-2xl font-bold mb-4">{categoryName}</h2>
      {categoryProducts.length > 0 ? (
        <div className="cards-cols grid grid-cols-4 gap-4">
          {categoryProducts.map((item) => {
            const currentQuantity = getCartItemQuantity(item.id);
            return (
              <div
                key={item.id}
                className={`border p-4 rounded-lg bg-[#0E4980] flex flex-col items-center ${
                  darkMode ? "bg-gray-700" : ""
                }`}
              >
                <div
                  className={`bg-[#C2DBF2] w-full flex flex-col justify-center items-center rounded-md ${
                    darkMode ? "bg-gray-500" : ""
                  }`}
                >
                  <Carousel
                    autoplay={true}
                    loop={true}
                    nextArrow={false}
                    prevArrow={false}
                    className="w-full h-full"
                  >
                    {item.images && item.images.length > 0 ? (
                      item.images.map((imgSrc, imgIndex) => (
                        <div
                          key={imgIndex}
                          className="w-full flex justify-center"
                        >
                          <img
                            src={imgSrc}
                            alt={`${item.name} image ${imgIndex + 1}`}
                            className="h-60 object-contain mb-10"
                          />
                        </div>
                      ))
                    ) : (
                      <div>Imagen no disponible</div>
                    )}
                  </Carousel>
                </div>
                <br />
                <div className="w-full h-56 flex flex-col mb-5 justify-start">
                  <h3 className="text-lg text-white font-semibold mb-4">
                    {item.name}
                  </h3>
                  <h3 className="text-lg whitespace-pre-line text-white font-semibold mb-4">
                    {item.more_information}
                  </h3>
                </div>
                <div className="w-full flex justify-center gap-4">
                  <button
                    className={`txt-card bg-blue-700 text-white px-4 py-2 rounded-lg w-[30%] text-sm ${
                      darkMode ? "bg-gray-500" : ""
                    }`}
                  >
                    <Link to={`/stock-devices-renting/${item.id}`} className="">
                      Ver más
                    </Link>
                  </button>
                  <input
                    type="number"
                    min="1"
                    value={quantities[item.id] || currentQuantity || 1}
                    onChange={(e) =>
                      handleQuantityChange(item.id, parseInt(e.target.value))
                    }
                    className={`border rounded-md p-2 w-[14%] ${
                      darkMode ? "text-black" : ""
                    }`}
                  />
                  <button
                    onClick={() =>
                      addToCart(item, quantities[item.id] || 1, true)
                    }
                    className={`txt-card2 bg-blue-300 text-white px-4 py-2 rounded-lg w-[60%] text-sm ${
                      darkMode ? "bg-gray-800" : ""
                    }`}
                  >
                    Agregar a cotizar
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <p className="text-xl text-red-600">
          No hay productos disponibles en esta categoría.
        </p>
      )}
    </div>
  );

  return (
    <>
      <Navbar />
      <div
        className={`w-full pt-20 flex flex-col items-center justify-center ${
          darkMode ? "bg-gray-900 text-white" : ""
        }`}
      >
        <div
          className={`flex  justify-center items-center bg-[url("https://res.cloudinary.com/dtpshcfjx/image/upload/v1728402000/bhbb39q0ii0ng2ihdlmu.jpg")] bg-no-repeat bg-cover bg-center w-full h-[60vh]`}
        >
          <div className="d-renting bg-black/45 w-full h-full flex flex-col gap-4 justify-center items-center text-[#ffffff]">
            <h1 className="text-8xl">
              ¡Venta y Renting de Dispositivos Usados!
            </h1>
            <h2 className="text-7xl">DaaS | IaaS | SaaS</h2>
          </div>
        </div>

        {/* Producto random en renting */}
        {/* {randomRentingProduct && (
          <div
            className={`h-[40vh] text-left text-black w-full flex items-center justify-center px-10`}
          >
            <div className="flex justify-center items-center w-[80%]">
              <div className={`${darkMode ? "text-white" : ""}`}>
                <h1 className="text-4xl font-bold mb-4">
                  {randomRentingProduct.name}
                </h1>
                <p className="text-lg mb-4">
                  {randomRentingProduct.description}
                </p>
                <Link to={`/stock-devices-renting/${randomRentingProduct.id}`}>
                  <button className="bg-blue-600 text-white px-6 py-3 rounded-lg">
                    Ver más
                  </button>
                </Link>
              </div>
              <div>
                <Carousel
                  autoplay={true}
                  loop={true}
                  nextArrow={false}
                  prevArrow={false}
                  className="w-full h-full"
                >
                  {randomRentingProduct.images &&
                  randomRentingProduct.images.length > 0 ? (
                    randomRentingProduct.images.map((imgSrc, imgIndex) => (
                      <div
                        key={imgIndex}
                        className="w-full flex justify-center"
                      >
                        <img
                          src={imgSrc}
                          alt={`${randomRentingProduct.name} image ${
                            imgIndex + 1
                          }`}
                          className="h-60 object-contain mb-10"
                        />
                      </div>
                    ))
                  ) : (
                    <div>No Image Available</div>
                  )}
                </Carousel>
              </div>
            </div>
          </div>
        )} */}

        {/* Barra de búsqueda */}
        <div className="container-full-e w-[80%] flex justify-start gap-5 my-8">
          <input
            type="text"
            placeholder="Buscar equipos..."
            className="border border-gray-300 px-4 py-2 w-[90%] rounded-l-lg"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <Link to="/stock-cart">
            <button
              className={`bg-blue-600 text-white px-4 py-2 rounded-lg relative ${
                darkMode ? "bg-gray-500" : ""
              }`}
            >
              Carrito
              {cartItems.length >= 0 && (
                <span className="absolute top-0 right-0 left-16 inline-block w-6 h-6 text-center text-white bg-red-600 rounded-full">
                  {cartItems.length}
                </span>
              )}
            </button>
          </Link>
        </div>

        {/* Filtro de marcas */}
        <div className="container-full-e w-[80%] flex justify-start gap-5 mb-8">
          {/* <h1 className="text-3xl">Productos en Renting</h1> */}
          <select
            value={selectedBrand}
            onChange={(e) => setSelectedBrand(e.target.value)}
            className={`border border-gray-300 px-4 py-2 w-[40%] rounded-lg ${
              darkMode ? "text-black" : ""
            }`}
          >
            <option value="todos">Todos</option>
            <option value="hp">HP</option>
            <option value="lenovo">Lenovo</option>
          </select>
        </div>

        {/* Mostrar productos por categoría */}
        {renderProducts(desktopRentingProducts, "Equipos de Escritorio")}
        {renderProducts(laptopRentingProducts, "Equipos Portátiles")}

        {/* Sección de productos en Venta */}
        {/* {renderProducts(filteredProducts, "Productos en Venta")} */}
      </div>
      <Footer />
    </>
  );
}

export default Ecommerce;
