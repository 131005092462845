import React, { createContext, useState, useEffect } from "react";
import Swal from "sweetalert2";

export const CartContext = createContext();

export const CartProvider = ({ children }) => {
  // Cargar productos desde localStorage al iniciar la aplicación
  const [cartItems, setCartItems] = useState(() => {
    const savedCart = localStorage.getItem("cartItems");
    return savedCart ? JSON.parse(savedCart) : [];
  });

  // Guardar productos en localStorage cada vez que cambie cartItems
  useEffect(() => {
    localStorage.setItem("cartItems", JSON.stringify(cartItems));
  }, [cartItems]);

  // Función para agregar o actualizar productos en el carrito
  const addToCart = (item, quantityToAdd = 1, replaceQuantity = false) => {
    const itemExists = cartItems.some((cartItem) => cartItem.id === item.id);

    if (itemExists) {
      // Si el producto ya está en el carrito, actualizar la cantidad
      setCartItems(
        cartItems.map((cartItem) =>
          cartItem.id === item.id
            ? {
                ...cartItem,
                quantity: replaceQuantity
                  ? quantityToAdd // Reemplazar cantidad si se está modificando desde Ecommerce.jsx
                  : cartItem.quantity + quantityToAdd, // Sumar cantidad si se está agregando desde otro lugar
              }
            : cartItem
        )
      );
      Swal.fire({
        icon: "success",
        title: replaceQuantity
          ? "Cantidad actualizada"
          : "Producto actualizado",
        text: replaceQuantity
          ? `La cantidad de ${item.name} ha sido modificada.`
          : `${item.name} ha sido incrementado en cantidad.`,
        confirmButtonText: "Genial",
      });
    } else {
      // Agregar el producto al carrito con la cantidad seleccionada
      setCartItems([...cartItems, { ...item, quantity: quantityToAdd }]);
      Swal.fire({
        icon: "success",
        title: "Producto agregado",
        text: `${item.name} ha sido agregado al carrito.`,
        confirmButtonText: "Genial",
      });
    }
  };

  // Función para modificar la cantidad de un producto
  const updateQuantity = (id, newQuantity) => {
    setCartItems(
      cartItems.map((item) =>
        item.id === id ? { ...item, quantity: newQuantity } : item
      )
    );
  };

  // Función para eliminar productos del carrito
  const removeFromCart = (id) => {
    setCartItems(cartItems.filter((item) => item.id !== id));
  };

  // Función para vaciar el carrito
  const clearCart = () => {
    setCartItems([]);
    Swal.fire({
      icon: "success",
      title: "Cotización realizad con exito!",
      text: "Nos pondremos en contacto contigo.",
      confirmButtonText: "OK",
    });
  };

  return (
    <CartContext.Provider
      value={{
        cartItems,
        addToCart,
        updateQuantity,
        removeFromCart,
        clearCart,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};
